.dashboard-filters {
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.dashboard-filters .form-group {
  position: relative;
  margin-bottom: 0;
}

.dashboard-filters .form-label {
  font-weight: 600;
  font-size: 0.875rem;
  color: #344767;
  margin-bottom: 0.5rem;
  display: block;
}

.dashboard-filters .form-select {
  border: 1px solid #e9ecef;
  border-radius: 6px;
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  color: #495057;
  background-color: #fff;
  transition: all 0.2s ease;
  height: 42px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.dashboard-filters .form-select:hover {
  border-color: #51cbce;
}

.dashboard-filters .form-select:focus {
  border-color: #51cbce;
  outline: 0;
  box-shadow: 0 3px 9px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.dashboard-filters .form-select option {
  padding: 0.5rem;
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .dashboard-filters {
    padding: 1rem;
  }
  
  .dashboard-filters .form-group:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.dashboard-filters-row {
  margin: 0 -0.5rem;
}

.dashboard-filters-row > [class*="col-"] {
  padding: 0 0.5rem;
}

.dashboard-filters .form-group {
  position: relative;
}

.dashboard-filters .form-group::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 65%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #51cbce;
  pointer-events: none;
} 