.enhanced-table {
  background: #ffffff;
  border-radius: 0.25rem;
  padding: 1rem;
}

.enhanced-table .table {
  margin-bottom: 0;
}

.enhanced-table th.sortable {
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.enhanced-table th.sortable:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.enhanced-table .pagination {
  margin-bottom: 0;
}

.enhanced-table .form-select {
  min-width: 150px;
  margin-left: 1rem;
}

.enhanced-table .table > :not(caption) > * > * {
  padding: 1rem 0.75rem;
}

.enhanced-table .input-group {
  max-width: 300px;
}

.enhanced-table .btn-outline-secondary {
  border-color: #ced4da;
}

.enhanced-table .btn-outline-secondary:hover {
  background-color: #f8f9fa;
  border-color: #ced4da;
  color: #6c757d;
}

.enhanced-table .table-responsive {
  min-height: 400px;
}

.enhanced-table .pagination .page-link {
  color: #51cbce;
}

.enhanced-table .pagination .active .page-link {
  background-color: #51cbce;
  border-color: #51cbce;
}

.enhanced-table .action-icons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  min-width: 140px;
  padding-right: 0.5rem;
}

.enhanced-table .action-icon {
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.enhanced-table .action-icon:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.enhanced-table .action-icon.text-info:hover {
  color: #0dcaf0;
}

.enhanced-table .action-icon.text-success:hover {
  color: #198754;
}

.enhanced-table .action-icon.text-danger:hover {
  color: #dc3545;
}

.enhanced-table .action-icon svg {
  width: 0.875rem;
  height: 0.875rem;
}

.enhanced-table th.sortable svg {
  margin-left: 0.5rem;
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
}

.enhanced-table .action-icons .btn-link {
  padding: 0.25rem;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: #51cbce;
  text-decoration: none;
}

.enhanced-table .action-icons .btn-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #3ea7a8;
}

.enhanced-table th:last-child,
.enhanced-table td:last-child {
  min-width: 140px;
  width: 140px;
  padding-right: 1rem !important;
}

.enhanced-table .action-icon .btn-link {
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enhanced-table .action-icon .btn-link svg {
  width: 0.875rem;
  height: 0.875rem;
}