.error-message-container {
  margin: 1rem;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-message-container .alert {
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.error-message-container .alert-heading {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.error-message-container p {
  margin-bottom: 0;
} 