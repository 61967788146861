.user-profile-container {
  position: relative;
  display: inline-block;
}

.user-icon {
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  color: #344767;
}

.user-icon:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.user-profile-card {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 250px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
}

.user-profile-card::before {
  content: '';
  position: absolute;
  top: -8px;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
}

.user-info {
  text-align: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.user-info h6 {
  margin-bottom: 0.5rem;
  color: #344767;
  font-weight: 600;
}

.user-info p {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.user-info .badge {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  background-color: #17c1e8;
}

/* Remove previous dropdown styles */
.dropdown-toggle::after,
.dropdown-menu,
.dropdown-item,
.dropdown-divider {
  display: none;
} 