.user-profile-image {
  position: relative;
}

.user-profile-image img {
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Form control styling */
.form-control, .form-select {
  border: 1px solid #e9ecef;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  background-color: #fff;
  transition: all 0.2s ease;
  height: 45px;
  width: 100%;
}

/* Read-only state styling */
.form-control:read-only,
.form-select:disabled {
  background-color: #f8f9fa;
  opacity: 1;
  color: #495057;
  border-color: #e9ecef;
  cursor: default;
}

.form-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  color: #344767;
  margin-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

/* Section headers */
.form-section-header {
  color: #344767;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 2rem 0 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f8f9fa;
}

/* Required field indicator */
.required-field::after {
  content: "*";
  color: #dc3545;
  margin-left: 4px;
}

/* Button spacing in modal footer */
.modal-footer .btn + .btn {
  margin-left: 1rem;
}

/* Button spacing and styling */
.modal-footer {
  display: flex;
  justify-content: flex-end !important;
  padding: 1rem 1.5rem;
  gap: 1rem;
}

.modal-footer .btn {
  min-width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Edit button styling */
.edit-button {
  margin-left: auto;
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  font-weight: 500;
  min-width: 120px;
}

.edit-button:hover {
  background-color: #138496;
  border-color: #117a8b;
}

/* Read-only styling for form controls */
.read-only-mode .form-control,
.read-only-mode .form-select {
  background-color: #f8f9fa;
  border-color: #e9ecef;
  pointer-events: none;
  color: #495057;
  opacity: 1;
}

.read-only-mode .form-label {
  color: #6c757d;
}

/* Hide required indicators in read mode */
.read-only-mode .required-field::after {
  display: none;
}

/* Edit mode toggle button */
.edit-mode-toggle {
  position: absolute;
  top: 1rem;
  right: 4rem;
  z-index: 1;
}

/* Card styling */
.status-card {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

.status-card .card-body {
  padding: 1.5rem;
}

/* Row spacing */
.row {
  margin-bottom: 1rem;
}

/* Column spacing */
.col-md-6 {
  padding: 0 1rem;
}

/* Button group */
.button-group {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

@media print {
  .form-control {
    print-color-adjust: exact;
  }
} 