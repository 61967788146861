.notification-container {
  position: relative;
  display: inline-block;
  margin-right: 1rem;
}

.notification-icon {
  font-size: 2rem;
  color: #000000;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.notification-icon:hover {
  color: #344767;
  transform: scale(1.1);
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.7rem;
  padding: 0.25rem 0.45rem;
  border-radius: 50%;
  background-color: #fb404b;
  border: 2px solid #fff;
  font-weight: bold;
}

.notification-card {
  position: absolute;
  top: 100%;
  right: 0;
  width: 320px;
  margin-top: 0.5rem;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.notification-list {
  max-height: 400px;
  overflow-y: auto;
}

.notification-item {
  padding: 0.75rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: background-color 0.2s ease;
}

.notification-item:hover {
  background-color: #f8f9fa;
}

.notification-item.unread {
  background-color: #f0f9ff;
}

.notification-content {
  flex: 1;
  margin-right: 0.5rem;
}

.notification-content p {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
}

.unread-badge {
  flex-shrink: 0;
  align-self: center;
} 