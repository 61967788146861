/* Modal sizing and centering */
.base-modal {
  max-width: 80vw !important;
  width: 80vw;
  height: 80vh;
  margin: 0 !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.base-modal .modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  border-radius: 0.5rem;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.base-modal .modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem 2rem;
  max-height: none !important;
}

.base-modal .modal-footer {
  padding: 1.25rem 2rem;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  justify-content: flex-end !important;
}

.base-modal .modal-header {
  padding: 1.25rem 2rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.modal-content-wrapper {
  padding: 0;
}

/* Scrollbar styling */
.base-modal .modal-body::-webkit-scrollbar {
  width: 8px;
}

.base-modal .modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.base-modal .modal-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.base-modal .modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Modal backdrop */
.modal-backdrop {
  opacity: 0.5 !important;
}

/* Read-only mode footer */
.base-modal .read-only-footer {
  justify-content: flex-end !important;
  padding: 1rem 1.5rem;
  border-top: 1px solid #e9ecef;
}

.base-modal .read-only-footer .btn {
  min-width: 120px;
}

/* Read-only mode body */
.base-modal .read-only-mode {
  background-color: #fff;
}

.base-modal .read-only-mode .form-control,
.base-modal .read-only-mode .form-select {
  background-color: #f8f9fa;
  border-color: #e9ecef;
  pointer-events: none;
} 